import {
  faPencilAlt,
  faUndo,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalFilters } from '../../components';
import { ModalContext } from '../../context';
import { toFixed } from '../../helpers/helper';
import { EditOrderModal } from '../../pages/OrdersHistory/EditOrderModal';
import { omitColumnsNotFromConfig } from '../helper';
import { Direction, TradingType } from '../../types/order';

import './PendingOrdersSchema.css';

const formatNumber = (num, maxBeforeDecimal, maxAfterDecimal) => {
  const [before, after] = num.toString().split('.');
  const formattedBefore = Number(before).toLocaleString('en-US', { maximumFractionDigits: 0 });
  const paddedBefore = formattedBefore.padStart(maxBeforeDecimal, ' ');
  const paddedAfter = (after || '').padEnd(maxAfterDecimal, '0');
  return `${paddedBefore}.${paddedAfter}`;
};

export const PendingOrdersSchema = (
  columnConfig,
  filters,
  setFilters,
  permissionNames,
  tradingTypeFilter,
  setTradingTypeFilter,
  tradingPairFilter,
  setTradingPairFilter,
  directionFilter,
  setDirectionFilter,
  pairNames,
  setSelectedRow,
  handleShowTPSL,
  setTP,
  setSL,
  setCoinAmount,
  setOrderRate,
  handleShowLimit,
  handleShowMarket,
  handleShowTrailing,
  setTrailingPrice,
  setStopRate,
  rates,
  setFutureOrderId,
  handleShowFuture,
  handleCancelSpotOrder,
) => {
  const { showModal, hideModal } = useContext(ModalContext);
  const isUserCanSeeClientCard = permissionNames.includes('clients');
  const isUserCanSeeLeadsCard = permissionNames.includes('leads');

  const columns = [
    {
      name: 'Order ID',
      selector: (row) => row._id,
      cell: (row) => (
        <span className="order-id-cell">
          {row._id}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Order ID'),
    },
    {
      name: 'User',
      selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
      sortable: true,
      cell: (row) => {
        const canViewUserDetails = true;

        if (canViewUserDetails) {
          return (
            <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
              {`${row.user.firstName} ${row.user.lastName}`}
            </Link>
          );
        }

        return `${row.user.firstName} ${row.user.lastName}`;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'User'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: TradingType.FUTURES, _id: TradingType.FUTURES }, { name: TradingType.SPOT, _id: TradingType.SPOT }]}
          filters={tradingTypeFilter}
          setFilters={setTradingTypeFilter}
          tabName="Trading Type"
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.tradingType,
      cell: (row) => (
        <span className={`badge ${row.tradingType === TradingType.FUTURES ? 'badge-futures' : 'badge-spot'}`}>
          {row.tradingType}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Trading Type'),
    },
    {
      name: (
        <ModalFilters
          data={pairNames}
          filters={tradingPairFilter}
          setFilters={setTradingPairFilter}
          tabName="Trading Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.tradingPair,
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Trading Pair'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: Direction.BUY, _id: Direction.BUY }, { name: Direction.SELL, _id: Direction.SELL }]}
          filters={directionFilter}
          setFilters={setDirectionFilter}
          tabName="Direction" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.direction,
      cell: (row) => (
        <span className={`badge ${row.direction === Direction.BUY ? 'badge-buy' : 'badge-sell'}`}>
          {row.direction}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Direction'),
    },
    {
      name: 'Swap',
      selector: (row) => row.swap,
      sortable: true,
      cell: (row) => {
        if (row.swap === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.swap, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Swap'),
    },
    {
      name: 'Commission',
      selector: (row) => row.commission,
      sortable: true,
      cell: (row) => {
        if (row.commission === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        const commission = row.commission === 0 || row.userInvestedAmount === 0 ? 0 : (row.userInvestedAmount * row.commission) / 100;

        return <span className="number-cell">{`${formatNumber(toFixed(commission, 2), 8, 2)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Commission'),
    },
    {
      name: 'Leverage',
      selector: (row) => row.leverage,
      sortable: true,
      cell: (row) => {
        if (row.leverage === 'N/A') return <span className="number-cell">N/A</span>;

        return <span className="number-cell">{`${toFixed(row.leverage, 0)}x`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Leverage'),
    },
    {
      name: 'Order Qty',
      selector: (row) => row.orderQty,
      sortable: true,
      cell: (row) => {
        if (row.orderQty === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.fromCurrency.symbol : row.toCurrency.symbol;
    
        return <span className="number-cell">{`${formatNumber(toFixed(row.orderQty, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      center: true,
      omit: omitColumnsNotFromConfig(columnConfig, 'Order Qty'),
    },
    {
      name: 'Entry Price',
      selector: (row) => row.tradeStartPrice,
      sortable: true,
      cell: (row) => {
        if (row.tradeStartPrice === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.tradeStartPrice, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Entry Price'),
    },
    {
      name: 'Order Volume',
      selector: (row) => row.orderVolume,
      sortable: true,
      cell: (row) => {
        if (row.orderVolume === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.orderVolume, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      center: true,
      minWidth: '350px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Order Volume'),
    },
    {
      name: 'Liquidation Price',
      selector: (row) => row.liquidationPrice,
      sortable: true,
      cell: (row) => {
        if (row.liquidationPrice === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.liquidationPrice, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      center: true,
      omit: omitColumnsNotFromConfig(columnConfig, 'Liquidation Price'),
    },
    {
      name: 'Position Margin',
      selector: (row) => row.userInvestedAmount,
      sortable: true,
      cell: (row) => {
        if (row.userInvestedAmount === 'N/A') return <span className="number-cell">N/A</span>;

        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.userInvestedAmount, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      center: true,
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Position Margin'),
    },
    {
      name: 'Created Time',
      selector: (row) => row.startTime,
      sortable: true,
      cell: (row) => moment(row.startTime).format('YYYY-MM-DD HH:mm:ss'),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Created Time'),
      center: true,
    },
    {
      name: 'TP/SL',
      selector: (row) => {
        if (row.tradingType === TradingType.SPOT) return 'N/A';

        const handleClick = () => {
          setSelectedRow(row.orderInfo);
          handleShowTPSL();
          if (row.tpsl) {
            setTP(row.takeProfitPrice);
            setSL(row.stopLossPrice);
          }
        };

        if (!row.tpsl) {
          return (
            <button
              type="button"
              onClick={handleClick}
              className="btn graph-table-btns text-white"
            >
              + Add
            </button>
          );
        }
        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return (
          <>
            {row.takeProfitPrice ? `${row.takeProfitPrice} ${currencySymbol}` : '-'}
            /
            {row.stopLossPrice ? `${row.stopLossPrice} ${currencySymbol}` : '-'}
            <button type="button" onClick={handleClick} className="btn graph-table-btns ms-2">
              <FontAwesomeIcon icon={faPencil} className="header-icon" />
            </button>
          </>
        );
      },
      sortable: false,
      center: true,
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'TP/SL'),
    },
    {
      name: 'Trailing Stop',
      selector: (row) => {
        if (row.tradingType === TradingType.SPOT) return 'N/A';

        const handleClick = () => {
          setSelectedRow(row.orderInfo);
          handleShowTrailing();
          if (row.tradeTrailingPrice) setTrailingPrice(row.tradeTrailingPrice);
        };
        const currencySymbol = row.tradingType === TradingType.SPOT && row.direction === Direction.SELL 
          ? row.toCurrency.symbol : row.fromCurrency.symbol;

        return row.tradeTrailingPrice ? (
          <>
            {`${row.tradeTrailingPrice} ${currencySymbol}`}
            <button type="button" onClick={handleClick} className="btn graph-table-btns ms-2">
              <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
            </button>
          </>
        ) : (
          <button type="button" onClick={handleClick} className="btn btn-success btn-sm me-1 p-1">
            + Add
          </button>
        );
      },
      sortable: false,
      minWidth: '200px',
      center: true,
      omit: omitColumnsNotFromConfig(columnConfig, 'Trailing Stop'),
    },
    {
      name: 'Close By',
      minWidth: '300px',
      selector: (row) => {
        if (row.tradingType === TradingType.SPOT) {
          return (
            <button 
              type="button" 
              onClick={() => { handleCancelSpotOrder(row.orderInfo); }}
              className="btn btn-danger btn-sm me-1 p-1"
            >
              Cancel
            </button>
          );
        }

        const handleLimitOrMarket = (type) => {
          setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage));
          setSelectedRow(row.orderInfo);
          setOrderRate(row.tradeStartPrice);
          if (type === 'Limit') {
            handleShowLimit();
          } else {
            handleShowMarket();
          }
        };

        const handleStop = () => {
          setStopRate(rates ? rates.find((line) => line.symbol === row.pairName)?.markPrice || 0 : 0);
          setFutureOrderId(row?._id);
          handleShowFuture();
        };

        return (
          <>
            {row?.status === 0 && (
              <>
                {/* <button type="button" onClick={() => handleLimitOrMarket('Limit')} className="btn btn-success btn-sm me-1 p-1">Limit</button>
                <button type="button" onClick={() => handleLimitOrMarket('Market')} className="btn btn-success btn-sm me-1 p-1">Market</button> */}
                <button type="button" className="btn btn-success btn-sm me-1 p-1" disabled>Limit</button>
                <button type="button" className="btn btn-success btn-sm me-1 p-1" disabled>Market</button>
              </>
            )}
            <button type="button" onClick={handleStop} className="btn btn-danger btn-sm me-1 p-1">Stop</button>
          </>
        );
      },
      sortable: true,
      omit: omitColumnsNotFromConfig(columnConfig, 'Close By'),
    },
  ];

  return columns;
};
